.hide-scrollbar {
  /* -moz- (Firefox) */
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;

  /* -ms- (Internet Explorer +10) */
  -ms-overflow-style: none;
}

.unstyled-link {
  text-decoration: none;
  color: inherit;
}

/* -webkit- (Chrome, Safari, newer versions of Opera) */
.hide-scrollbar::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

.avg-clickable {
  cursor: pointer;
  --webkit-tap-highlight-color: none;
  userSelect: none;
  text-decoration: none;
}

/* Firefox */
.scrollBarGrape {
  scrollbar-width: thin;
  scrollbar-color: #B4C1D5 transparent;
}

/* Chrome, Edge and Safari */
.scrollBarGrape::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.scrollBarGrape::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: transparent;
}

.scrollBarGrape::-webkit-scrollbar-track:hover {
  background-color: transparent;
}

.scrollBarGrape::-webkit-scrollbar-track:active {
  background-color: transparent;
}

.scrollBarGrape::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #B4C1D5;
}

.scrollBarGrape::-webkit-scrollbar-thumb:hover {
  background-color: #B4C1D5;
}

.scrollBarGrape::-webkit-scrollbar-thumb:active {
  background-color: #B4C1D5;
}