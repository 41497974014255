.chart-tooltip {
  position: absolute;
  pointer-events: none;

  & > .tooltip-container {
    height: 52px;
    cursor: pointer;
    padding: 9px 12px;
    position: relative;
    background: #fefefe;
    border-radius: 16px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));

    & > .tooltip-data {
      display: flex;
      position: inherit;
      user-select: none;
      flex-direction: column;

      & > .tooltip-price {
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        margin-bottom: 4px;
        white-space: nowrap;
      }

      & > .tooltip-date {
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 4px;
        white-space: nowrap;
      }
    }

    &:before {
      content: "";
      width: 20px;
      height: 20px;
      position: absolute;
      border-radius: 2px;
      box-shadow: inherit;
      background: #fefefe;
      transform: rotate(45deg) skew(10deg, 10deg);
    }

    &.top-tooltip-container:before {
      top: calc(100% - 18px);
    }

    &.bottom-tooltip-container:before {
      top: -10px;
    }

    &.right-tooltip-container:before {
      left: 12px;
    }

    &.left-tooltip-container:before {
      left: calc(100% - 40px);
    }

    &.dark-tooltip-container {
      background: #000000;
      filter: drop-shadow(0px 4px 4px rgba(255, 255, 255, 0.15));

      &:before {
        background: #000000;
      }
    }
  }
}
