@font-face {
  font-family: "SFPro Display";
  src: url("sf-pro/SFProDisplay-Light.eot");
  src: url("sf-pro/SFProDisplay-Light.eot?#iefix") format("embedded-opentype"),
    url("sf-pro/SFProDisplay-Light.woff") format("woff"),
    url("sf-pro/SFProDisplay-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "SFPro Display";
  src: url("sf-pro/SFProDisplay-Regular.eot");
  src: url("sf-pro/SFProDisplay-Regular.eot?#iefix") format("embedded-opentype"),
    url("sf-pro/SFProDisplay-Regular.woff") format("woff"),
    url("sf-pro/SFProDisplay-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SFPro Display";
  src: url("sf-pro/SFProDisplay-Medium.eot");
  src: url("sf-pro/SFProDisplay-Medium.eot?#iefix") format("embedded-opentype"),
    url("sf-pro/SFProDisplay-Medium.woff") format("woff"),
    url("sf-pro/SFProDisplay-Medium.ttf") format("truetype");
  font-weight: 510;
  font-style: normal;
}

@font-face {
  font-family: "SFPro Display";
  src: url("sf-pro/SFProDisplay-Semibold.eot");
  src: url("sf-pro/SFProDisplay-Semibold.eot?#iefix") format("embedded-opentype"),
    url("sf-pro/SFProDisplay-Semibold.woff") format("woff"),
    url("sf-pro/SFProDisplay-Semibold.ttf") format("truetype");
  font-weight: 590;
  font-style: normal;
}

@font-face {
  font-family: "SFPro Display";
  src: url("sf-pro/SFProDisplay-Bold.eot");
  src: url("sf-pro/SFProDisplay-Bold.eot?#iefix") format("embedded-opentype"),
    url("sf-pro/SFProDisplay-Bold.woff") format("woff"),
    url("sf-pro/SFProDisplay-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
