html,
body,
#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: "SFPro Display", sans-serif;
}

body {
  a:visited {
    color: inherit;
  }

}

::-webkit-input-placeholder {
  opacity: 1 !important;
  color: #616161 !important;
}

:-ms-input-placeholder {
  opacity: 1 !important;
  color: #616161 !important;
}

::-moz-placeholder {
  opacity: 1 !important;
  color: #616161 !important;
}

:-moz-placeholder {
  opacity: 1 !important;
  color: #616161 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}
