@import "fonts";
@import "reset";
@import "chart-tooltip";
@import "common";


///* Скрыть вертикальную полосу прокрутки */
.hideScrollBarVisible::-webkit-scrollbar {
  display: none;
}
.hideScrollBarVisible {
  display: none;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

///* Скрыть горизонтальную полосу прокрутки (если нужно) */
.hideScrollBarVisible::-webkit-scrollbar-horizontal {
  display: none;
}

///* Скрыть вертикальную полосу прокрутки (если нужно) */
.hideScrollBarVisible::-webkit-scrollbar-vertical {
  display: none;
}

///* Скрыть кнопки прокрутки (если есть) */
.hideScrollBarVisible ::-webkit-scrollbar-button {
  display: none;
}
